// @ts-nocheck
import { useState, useEffect, useRef } from 'react';
import { Dialog, IconButton, ImageList, ImageListItem, Stack, Toolbar, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Slider from 'react-slick';
import Lightbox from '../../../Components/LightBox';
import { deleteImage } from '../../../store/slices/images';
import { useAppDispatch, useAppSelector } from '../../../store';
import { IImages } from '../../../types';
import { useAddImagesMutation, useAddMultipleImagesMutation } from '../../../store/services/photo';
import { useTranslation } from 'react-i18next';

export default function Callery({ selectMainImage, selectMainImageId }: { selectMainImage?: (id: number) => void, selectMainImageId?: number }) {
  const sliderRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [index, setIndex] = useState(1);
  const images = useAppSelector((state) => state.images.images);
  const imagescONT = useAppSelector((state) => state.images);
  // const [addImages, { isLoading, error }] = useAddImagesMutation({});
  const [addMultipleImages, { isLoading, error }] = useAddMultipleImagesMutation({});
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleClickOpen = (current: number) => {
    setOpen(true);
    setIndex(current);
  };
  const handleCloseGallery = () => {
    setOpenGallery(false);
  };
  const handleOpenGallery = () => {
    setOpenGallery(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteUploadFiles = (ind: any) => {
    dispatch(deleteImage(ind))
  }

  const onChangeHandler = (event: any) => {
    const formdata = new FormData();
    const files: any = Array.from(event.target.files);
    console.log(files);
    files.forEach((file: any) => {
      formdata.append('images', file); // Or just 'files' depending on backend
    });

    if (files.length > 0) {
      addMultipleImages(formdata);
    }
  };
  useEffect(() => {
    try {
      if (!open) {
        setIndex(1)
      }
    } catch (error) {
      console.log(error);
    }

  }, [open]);

  useEffect(() => {
    if (selectMainImageId && sliderRef?.current) {
      const findSelectedItemIndex=images.findIndex((el:any) => el.id === selectMainImageId);
      sliderRef?.current?.slickGoTo(findSelectedItemIndex);
    }
  }, [selectMainImageId])

  const dialogSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const settings = {
    customPaging: function (i: number) {
      return (
        <a>
          <img src={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${images[i]?.path}`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div>
      <Box sx={{ marginTop: 8 }} onClick={handleOpenGallery}><AddIcon /></Box>

      <Box sx={{ maxWidth: '100%', width: 400, marginTop: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {images.length > 0 && <Box sx={{ marginBottom: '100px' }}>
            <Slider {...settings} ref={sliderRef}>
              {images.map((el: IImages, ind: number) => (<div key={ind} onClick={() => handleClickOpen(ind)}>
                <img
                  src={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${el.path}`}
                  alt='img'
                  loading="lazy"
                  className='w-100 add-car-img'
                />
              </div>))}
            </Slider>
          </Box>}
          <Box>
            <Dialog
              fullWidth={true}
              maxWidth="md"
              open={open}
              onClose={handleClose}
            >
              <Lightbox index={index} itemData={images} />
            </Dialog>
          </Box>
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={openGallery}
            onClose={handleCloseGallery}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
              </IconButton>
            </Toolbar>
            <Box sx={{ maxWidth: '100%', width: '80%', margin: 'auto', gap: 3 }}>
              <Stack sx={{ display: 'flex', justifyContent: 'center', mb: 3 }} direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" component="label">
                  {t('upload')}
                  <input hidden accept="image/*" type="file" multiple onChange={(e) => {
                    onChangeHandler(e)
                  }} />
                </Button>
              </Stack>
              <ImageList sx={{ width: 500, height: 450, margin: 'auto' }} cols={3} rowHeight={164}>
                {images.map((item: any, ind: number) => {
                  return <Box
                    onClick={() => selectMainImage(item?.id)}
                    sx={{ position: 'relative' }}
                    className='imageListItem'
                    key={ind}>
                    <Box sx={{
                      position: 'absolute',
                      top: 3,
                      right: 3,
                      background: 'white',
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      zIndex: 1000,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                      onClick={() => handleDeleteUploadFiles(ind)}
                    ><CloseIcon /></Box>
                    <ImageListItem key={item}>
                      <img
                        src={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${item.path}`}
                        srcSet={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${item.path}`}
                        alt='img'
                        loading="lazy"
                        className={`add-images ${selectMainImageId && selectMainImageId === item.id ? "active-image" : ""}`}
                      />
                    </ImageListItem>
                  </Box>
                })}
              </ImageList>
             {images.length>0 && <Stack sx={{ display: 'flex', justifyContent: 'center', mb: 3 }} direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" component="label" onClick={handleCloseGallery}>
                  {t('save')}
                </Button>
              </Stack>}
            </Box>

          </Dialog>
        </Box>
      </Box >
    </div >
  );
}